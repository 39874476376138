import { Zero } from '@/constants'
import { bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { autorun } from 'mobx'
import Web3 from 'web3'
import { blockchainHandler } from '.'
import kycAbi from './abis/kyc.abi.json'
import { Erc20Contract } from './erc20-contract'

const cacheds: {
  [address: string]: KycContractSolidity
} = {}

export class KycContractSolidity {
  contract: any
  tradeErc20Contract!: Erc20Contract
  tradeErc20Decimals = 18
  fee = Zero
  router = ''
  _loadTask?: Promise<any>
  _loaded = false

  private constructor(public address: string, public web3: Web3) {
    this.contract = new web3.eth.Contract(kycAbi as any, address)
    autorun(() => {
      if (walletStore.walletConnected && walletStore.chainId === (web3 as any).chainId) {
        this.injectProvider(walletStore.web3!)
      }
    })
  }

  static getInstance(address: string, web3: Web3) {
    address = Web3.utils.toChecksumAddress(address)
    if (!cacheds[address]) cacheds[address] = new KycContractSolidity(address, web3)
    return cacheds[address]
  }

  injectProvider(web3: Web3) {
    this.web3 = web3
    this.contract = new web3.eth.Contract(kycAbi as any, this.address)
    if (this.tradeErc20Contract) {
      this.tradeErc20Contract.injectProvider(web3)
    }
  }
  init(): Promise<any> {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }
    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const web3 = this.web3
        const methods = this.contract.methods
        const [tradeErc20, fee, router] = (await blockchainHandler.etherBatchRequest(this.web3, [
          methods.tradeErc20(),
          methods.fee(),
          methods.router(),
        ])) as any
        this.fee = bnHelper.fromDecimals(fee)
        this.tradeErc20Contract = new Erc20Contract(tradeErc20, web3, this.tradeErc20Decimals)
        this.router = router
        if (web3 !== this.web3) {
          this.tradeErc20Contract.injectProvider(this.web3)
        }
        this._loaded = true
        resolve(null)
      } catch (e) {
        console.log('Init kyc contract failed', e)
        reject(e)
        this._loadTask = undefined
      }
    })
    return this._loadTask
  }

  userTradeTokenBalance(account: string): Promise<FixedNumber> {
    return this.tradeErc20Contract.getTokenAmount(account)
  }

  isApproved = async (account) => {
    return await this.tradeErc20Contract.isApproved(account, this.router)
  }
  approve = async (account) => {
    return await this.tradeErc20Contract.approve(account, this.router)
  }
  join = async (account) => {
    return sendRequest(this.contract.methods.join(), account)
  }
  isJoined = async (account: string) => {
    return this.contract.methods.kycUserStatus(account).call()
  }
}

function sendRequest(fx, from): Promise<any> {
  return new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve(''))
      .on('error', (error) => reject(error))
  })
}
