





















































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Prop } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { KycViewModel } from '../viewmodels/kyc-viewmodel'

@Observer
@Component({
  components: {},
})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: KycViewModel
  @Prop({ default: false }) isLight
  walletStore = walletStore
}
