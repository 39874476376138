import { FarmContractSolana, KycUserAccount } from '@/blockchainHandlers/farm-contract-solana'
import { KycContractSolidity } from '@/blockchainHandlers/kyc-solidity'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Zero } from '@/constants'
import { bnHelper } from '@/helpers/bignumber-helper'
import { PoolStore } from '@/modules/ido/stores/pool-store'
import { apiService } from '@/services/api-service'
import { walletStore } from '@/stores/wallet-store'
import {
  MessageSignerWalletAdapter,
  SignerWalletAdapter,
  WalletAdapter,
  WalletNotFoundError,
  WalletSignTransactionError,
  WalletTimeoutError,
  WalletWindowClosedError,
} from '@solana/wallet-adapter-base'
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  Wallet,
} from '@solana/wallet-adapter-wallets'
import SynapsClient from '@synaps-io/verify.js'
import { first, get, map } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import Web3 from 'web3'

export class KycViewModel {
  @observable jwtJson = ''

  @observable poolStore?: PoolStore

  @observable loadingKycState = false
  @observable loadingCounter = 0
  @observable showConnectSolanaDialog = false
  @observable crosschainAccount = ''

  @observable passedKycNetwork: any
  @observable passedKycCheckbox = false
  @observable solWalletItems = [getPhantomWallet(), getSolletExtensionWallet(), getSolletWallet(), getSolflareWallet()]
  @observable selectedWallet: Wallet | null = null
  @observable selectedAdapter: WalletAdapter | SignerWalletAdapter | MessageSignerWalletAdapter | null = null
  @observable kycSignature = ''
  @observable signedKycAddress = false
  @observable userInfo: any = undefined
  @observable investor: any = undefined
  @observable sessionId: any = ''
  @observable synapsClient: any = {}
  @observable isConnectingKycNetwork = false
  @observable isLedgerKycWallet = false
  @observable showKycFeeDialog = false
  @observable kycContractSolidity: undefined | KycContractSolidity = undefined
  @observable userTradeTokenBalance = Zero
  @observable isApprovedKycRouter = false
  @observable kycFee = Zero
  @observable loadingKycContract = false
  @observable approvingKycRouter = false
  @observable joinKycLoading = false
  @observable isJoinedKycContract = false
  @observable farmHandler?: FarmContractSolana = undefined
  @observable kycUserAccount: KycUserAccount | undefined = undefined

  kycNetworkList = [
    {
      title: 'SOL',
      value: 'sol',
    },
    {
      title: 'BSC',
      value: 'bsc',
    },
    {
      title: 'ETH',
      value: 'eth',
    },
  ]
  stakedNetworkList = [
    {
      title: 'SOL',
      value: 'sol',
    },
  ]

  _disposers: IReactionDisposer[] = []
  destroy() {
    this._disposers.forEach((d) => d())
  }

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.account,
        (x) => {
          if (!x) this.resetCache()
          if (x) {
            this.onLoadingPage()
            this.loadKycContract()
          }
        },
        { fireImmediately: true }
      ),
      reaction(
        () => this.crosschainAccount,
        () => {
          this.resetSignedMessage()
        },
        { fireImmediately: true }
      ),
    ]
  }

  @asyncAction *fetchKycUserAccount() {
    try {
      this.kycUserAccount = yield this.farmHandler?.getKycUserAccount(walletStore.account)
    } catch {
      this.kycUserAccount = undefined
    }
  }

  @asyncAction *fetchUserContrainsSolana() {
    const [userBalance, ...res] = yield Promise.all([
      this.farmHandler?.getUserKycTradeTokenBalance(walletStore.account),
      this.fetchKycUserAccount(),
    ])
    this.isJoinedKycContract = !!this.kycUserAccount
    this.userTradeTokenBalance = userBalance
  }

  //solana contract
  @asyncAction *loadFarm() {
    if (walletStore.account && walletStore.connectedSolProvider) {
      if (!this.farmHandler) {
        this.farmHandler = FarmContractSolana.getInstance()
        this.farmHandler.injectProvider(walletStore.connectedSolProvider)
        yield this.farmHandler.loadIfNeed()
        const stateAccount = yield this.farmHandler.getKycConfigAccount()
        this.kycFee = bnHelper.fromDecimals(stateAccount.fee, 6)
      } else {
        this.farmHandler.injectProvider(walletStore.connectedSolProvider)
      }
      yield this.fetchUserContrainsSolana()
    }
  }

  @asyncAction *fetchUserContrainsSolidity() {
    this.isApprovedKycRouter = yield this.kycContractSolidity?.isApproved(walletStore.account)
    this.userTradeTokenBalance = yield this.kycContractSolidity?.userTradeTokenBalance(walletStore.account)
  }

  //solidity contract
  @asyncAction *loadKycSolidityContract() {
    if (!this.kycContractSolidity) {
      this.kycContractSolidity = KycContractSolidity.getInstance(
        process.env.VUE_APP_KYC_CONTRACT_ADDRESS!,
        walletStore.web3!
      )
      yield this.kycContractSolidity?.init()
    }
    this.isJoinedKycContract = yield this.kycContractSolidity.isJoined(walletStore.account)
    if (this.isJoinedKycContract) return
    this.kycFee = this.kycContractSolidity?.fee
    yield this.fetchUserContrainsSolidity()
  }

  //load contract
  @asyncAction *loadKycContract() {
    try {
      this.loadingKycContract = true
      if (walletStore.chainType !== 'sol') {
        yield this.loadKycSolidityContract()
      } else if (walletStore.connectedSolProvider) {
        yield this.loadFarm()
      }
    } catch (e) {
      console.log('loadKycContract failed: ', e)
      snackController.error('Kyc contract load failed')
    } finally {
      this.loadingKycContract = false
    }
  }

  @asyncAction *approveKycRouter() {
    try {
      this.approvingKycRouter = true
      yield this.kycContractSolidity?.approve(walletStore.account)
      snackController.success('Approve contract successfully')
      this.isApprovedKycRouter = true
    } catch (e) {
      snackController.error('Approve  contract failed')
    } finally {
      this.approvingKycRouter = false
    }
  }

  @asyncAction *joinKycContract() {
    try {
      this.joinKycLoading = true
      if (walletStore.chainType === 'sol') {
        yield this.farmHandler?.createKycUser(walletStore.account)
      } else {
        yield this.kycContractSolidity?.join(walletStore.account)
      }
      snackController.success('Pay kyc fee successfully')
      this.isJoinedKycContract = true
      this.showKycFeeDialog = false
      this.createSynapsSession()
    } catch (e) {
      snackController.error('Pay kyc fee failed')
    } finally {
      this.joinKycLoading = false
    }
  }

  @asyncAction *openKycFeedDialog() {
    if (!this.isJoinedKycContract) {
      this.showKycFeeDialog = true
      try {
        if (walletStore.chainType !== 'sol') yield this.fetchUserContrainsSolidity()
      } catch (e) {
        console.log('fetchUserTradeTokenBalance')
      }
    } else {
      yield this.createSynapsSession()
    }
  }
  @asyncAction *createSynapsSession() {
    try {
      this.loadingKycState = true
      const res: any = yield apiService.applies.createSession({
        investorId: this.investor?.id,
        chainId: walletStore.chainId,
        chainType: walletStore.chainType,
        account: walletStore.account,
      })
      this.investor = yield apiService.investors.findOne(this.investor?.id)
      this.sessionId = res?.synapsSessionId
      this.synapsClient = new SynapsClient(this.sessionId, 'individual')
      this.synapsClient.init()
      this.synapsClient.on('finish', async () => {
        this.investor = await apiService.investors.findOne(this.investor?.id)
      })
    } catch (e) {
      try {
        const errorMsg = this.getServerError('updateKycState', e)
        apiService.logs.create({
          investor: this.investor?.id,
          user: this.userInfo?.id,
          error: errorMsg,
          data: JSON.stringify(e),
        })
      } catch (err) {
        //
      }
      snackController.commonError(e)
    } finally {
      this.loadingKycState = false
    }
  }

  @action getServerError(functionName, err) {
    let message
    if (err && typeof err === 'string') message = err
    else {
      message = err?.message || err?.msg || 'Please try again'
    }
    const apiError = get(err, 'response.data.message')
    console.error(apiError, err)
    if (apiError) {
      if (apiError instanceof Array) {
        let errMsg = first(apiError)
        if (errMsg) errMsg = errMsg.messages
        if (errMsg) errMsg = first(errMsg)
        if (errMsg) errMsg = errMsg.message
        if (errMsg) message = errMsg
      } else if (apiError instanceof String || typeof apiError === 'string') {
        message = apiError as string
      }
    }

    return `${functionName}: ${message}`
  }

  @action telegramChanelSupport() {
    window.open('https://t.me/wagglenetwork', '_blank')
  }
  @action customerSupport() {
    window.open('https://t.me/anhdt17', '_blank')
  }

  //new flow
  @action resetSignedMessage() {
    this.kycSignature = ''
    this.signedKycAddress = false
    this.isLedgerKycWallet = false
  }

  @action.bound
  changeShowConnectSolanaDialog(value) {
    this.showConnectSolanaDialog = value
  }
  @action.bound changePassedKycNetwork(value) {
    this.passedKycNetwork = value
  }

  @asyncAction *signMessage(account, chainType, nonce, selectedAdapter: any = null) {
    if (!account) return ''
    const message = `https://app.waggle.network/projects wants to: \n Sign message with account \n ${account} - One time nonce: ${nonce}`
    const data = new TextEncoder().encode(message)
    if (chainType === 'sol') {
      //solana sign message
      const a = (selectedAdapter || this.selectedAdapter) as any
      let res
      if (a.signMessage) {
        res = yield a.signMessage(data)
      } else {
        res = yield a._wallet.signMessage(data)
      }
      return Object.values(res?.signature || res)
    } else {
      //bsc sign message
      if (typeof window === 'undefined') {
        return ''
      }
      if (window.ethereum) {
        const request = { method: 'personal_sign', params: [message, account] }
        return yield window.ethereum.request(request)
      } else {
        throw new Error('Plugin Metamask is not installed!')
      }
    }
  }

  @asyncAction *kycOnSignMessage() {
    try {
      loadingController.increaseRequest()
      const crosschainUser = yield this.registerCrosschainUser()
      this.isLedgerKycWallet = crosschainUser?.data?.isLedger ? true : false
      if (!this.isLedgerKycWallet) {
        this.kycSignature = yield this.signMessage(
          this.crosschainAccount,
          this.passedKycNetwork,
          crosschainUser?.nonce || ''
        )
      }
      const payload: any = {
        walletAddress: walletStore.account,
        kycAddress: this.crosschainAccount,
      }
      if (!this.isLedgerKycWallet) {
        payload.kycSignature = this.kycSignature
      }

      const isValidKycAddress = yield apiService.investors.checkKycAddresss(payload)

      if (isValidKycAddress) {
        this.passedKycCheckbox = true
        this.signedKycAddress = true
        snackController.success('Confirm ownership successfully!')
      } else {
        snackController.error('Error occured when confirm ownership!')
      }
    } catch (e) {
      if (e instanceof WalletSignTransactionError) {
        snackController.error('User rejected the request')
      } else snackController.commonError(e)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *mergeKycAddress() {
    try {
      this.loadingKycState = true
      if (this.signedKycAddress) {
        const mergeKycPayload: any = {
          walletAddress: walletStore.account,
          kycAddress: this.crosschainAccount,
          investorId: this.investor?.id,
        }
        if (!this.isLedgerKycWallet) {
          mergeKycPayload.kycSignature = this.kycSignature
        }
        const updatedUser = yield apiService.investors.mergeKycAddress(mergeKycPayload)
        if (updatedUser && updatedUser.investor) {
          this.investor = yield apiService.investors.findOne(updatedUser.investor.id)
          this.sessionId = this.investor?.synapsSessionId
        }
        this.passedKycCheckbox = false
        snackController.success('Confirm KYC State successfully!')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingKycState = false
    }
  }

  @asyncAction *connectSolana(wallet) {
    try {
      loadingController.increaseRequest()
      yield this.disconnectSolana()
      const adapter = wallet.adapter()
      if (!adapter) return
      if (!adapter.connected) yield adapter.connect()
      this.selectedWallet = wallet
      this.selectedAdapter = adapter
      this.crosschainAccount = adapter.publicKey?.toString() || ''
      ;(adapter as any).on('disconnect', () => this.disconnectSolana())
      ;(adapter as any).on('error', (err) => snackController.error(`${err.name} ${err.message}`))
      this.changeShowConnectSolanaDialog(false)
      return true
    } catch (error) {
      if (error instanceof WalletNotFoundError) {
        snackController.error(`Plugin ${wallet.name} is not installed!`)
      } else if (error instanceof WalletWindowClosedError) {
        snackController.error(`The wallet window is closed!`)
      } else if (error instanceof WalletTimeoutError) snackController.error('Wallet timeout error!')
      else snackController.error(error as any)
      console.error(error)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }
  @asyncAction *disconnectSolana() {
    const adapter = this.selectedAdapter
    if (adapter) {
      this.crosschainAccount = ''
      this.selectedAdapter = null
      this.selectedWallet = null
      adapter.removeAllListeners('disconnect')
      adapter.removeAllListeners('error')
      adapter.removeAllListeners()
      yield adapter.disconnect().catch()
    }
  }
  @asyncAction *connectKycNetwork() {
    if (this.passedKycNetwork === 'sol') {
      this.changeShowConnectSolanaDialog(true)
      //TODO: add solana check kyc
    } else {
      if (typeof window === 'undefined') {
        return false
      }
      const eth: any = window.ethereum
      if (eth) {
        try {
          this.isConnectingKycNetwork = true
          yield eth.request({ method: 'eth_requestAccounts' })
          const accounts = yield window.ethereum.request({ method: 'eth_accounts' })
          eth.removeListener('accountsChanged', this.ethereumConfigChanged)
          eth.on('accountsChanged', this.ethereumConfigChanged)
          this.crosschainAccount = accounts.length ? Web3.utils.toChecksumAddress(accounts[0]) : ''
          return true
        } catch (e) {
          snackController.error(e.message || e.msg)
        } finally {
          this.isConnectingKycNetwork = false
        }
      } else {
        throw new Error('Plugin Metamask is not installed!')
      }
      return false
    }
  }
  ethereumConfigChanged = (account) => {
    const currentAccount = (account || [])[0] || ''
    if (!currentAccount) return
    this.crosschainAccount = Web3.utils.toChecksumAddress(currentAccount)
  };

  @asyncAction *registerCrosschainUser() {
    let users
    try {
      users = yield apiService.users.find({ username: this.crosschainAccount }, { _limit: 1 }) || []
    } catch (e) {
      //
    }
    let user = users[0]
    if (!user) {
      user = yield apiService.users.signUp(this.crosschainAccount)
    }
    return user
  }

  //old flow
  @action onLoadingPage() {
    this.loadingCounter++
    Promise.all([this.resetCache(), this.login()]).finally(() => {
      this.loadingCounter = this.loadingCounter > 0 ? this.loadingCounter - 1 : 0
    })
  }

  @action resetCache() {
    this.jwtJson = ''
    this.userInfo = undefined
    this.investor = undefined
    this.passedKycNetwork = undefined
    this.passedKycCheckbox = false
    this.signedKycAddress = false
    this.synapsClient = {}
    walletStore.changeJwt('')
    this.crosschainAccount = ''
  }

  @asyncAction *login() {
    if (!this.isLogin && walletStore.account) {
      walletStore.resetJwt()
      let getUser: any = []
      try {
        getUser = yield apiService.users.find({ username: walletStore.account }, { _limit: 1 }) || []
      } catch (e) {
        //
      }
      let user = getUser[0]
      if (!user) {
        user = yield apiService.users.signUp(walletStore.account)
      }
      const isLedger = user?.data?.isLedger
      let signature
      if (!isLedger) {
        try {
          signature = yield this.signMessage(
            walletStore.account,
            walletStore.chainType,
            user?.nonce,
            walletStore.selectedAdapter
          )
        } catch (e) {
          yield walletStore.disconnectAccount()
        }
        if (!signature) return
      } else signature = (Math.random() + 1).toString(36).substring(2)
      try {
        const res = yield apiService.users.signIn({
          publicAddress: walletStore.account,
          signature,
        })
        if (res) {
          this.userInfo = res.user
          this.investor = res.investor
          this.jwtJson = res.jwt
          walletStore.changeJwt(res.jwt)
          this.sessionId = this.investor?.synapsSessionId
        }
        if (this.sessionId) {
          this.synapsClient = new SynapsClient(this.sessionId, 'individual')
          this.synapsClient.init()
          this.synapsClient.on('finish', async () => {
            this.investor = await apiService.investors.findOne(this.investor?.id)
          })
        }
      } catch (e) {
        snackController.commonError(e)
      }
    }
  }

  @action.bound showKycFeeDialogChange(value) {
    this.showKycFeeDialog = value
  }

  @computed get notEnoughKycFee() {
    return bnHelper.gt(this.kycFee, this.userTradeTokenBalance)
  }

  @computed get tradeTokenForKycFee() {
    return walletStore.chainType === 'sol' ? 'USDC' : 'BUSD'
  }

  @computed get synapsStates() {
    return this.investor?.synapsStates || {}
  }
  @computed get isKycSubmitted() {
    return !!this.investor?.status
  }
  @computed get isSessionIdCreated() {
    return this.investor && this.sessionId
  }
  @computed get kycRejectedReasons() {
    const synapsStates = map(Object.values(this.synapsStates), 'reason')
    return synapsStates.filter((x) => !!x)
  }
  @computed get isKycPending() {
    return this.investor?.status === 'kyc-pending'
  }
  @computed get isIdentitySubmitted() {
    return this.synapsStates && this.synapsStates['IDENTITY']
  }
  @computed get isIdentityPending() {
    return this.synapsStates && this.synapsStates['IDENTITY'] && this.synapsStates['IDENTITY'].state === 'SUBMITTED'
  }
  @computed get isLivenessSubmitted() {
    return this.synapsStates && this.synapsStates['LIVENESS']
  }
  @computed get isLivenessPending() {
    return this.synapsStates && this.synapsStates['LIVENESS'] && this.synapsStates['LIVENESS'].state === 'SUBMITTED'
  }
  @computed get isKycFinalRejected() {
    return this.investor?.status === 'kyc-banned'
  }
  @computed get isKycVerified() {
    return this.investor?.status === 'kyc-verified'
  }
  @computed get passedKycNetworkInvalid() {
    if (!this.passedKycNetwork) return true
    return !(
      (this.passedKycNetwork === 'sol' && walletStore.chainType !== 'sol') ||
      (this.passedKycNetwork !== 'sol' && walletStore.chainType === 'sol')
    )
  }
  @computed get kycCheckBoxDone() {
    return this.passedKycCheckbox && this.signedKycAddress
  }

  @computed get isLogin() {
    return !!this.jwtJson
  }

  @computed get loadingPage() {
    return this.loadingCounter > 0
  }
}
