





























































import { Component, Provide, Vue, Inject } from 'vue-property-decorator'
import { AppProvider, appProvider } from '../../../app-providers'
import { Observer } from 'mobx-vue'
import { IdoApplyViewModel } from '../viewmodels/ido-apply-viewmodel'
import { alertController } from '@/components/alert/alert-controller'

@Observer
@Component
export default class extends Vue {
  @Provide() providers: AppProvider = appProvider
  @Inject() vm!: IdoApplyViewModel
  private readonly SOLLET_WALLET_NAME = 'Sollet'

  copyAddress() {
    navigator.clipboard.writeText(this.vm.crosschainAccount || '')
  }

  connectSolana(wallet) {
    if (wallet === this.vm.selectedWallet) {
      this.vm.disconnectSolana()
    } else if (wallet.name === this.SOLLET_WALLET_NAME) {
      alertController
        .confirm(
          'Warning',
          'The Sollet wallet sometime encounter error when register for whitelist. Are you sure you want to use this wallet?',
          'Continue',
          'Cancel'
        )
        .then((confirm) => {
          if (confirm) this.vm.connectSolana(wallet)
        })
    } else {
      this.vm.connectSolana(wallet)
    }
  }
}
