




























































































































































































import { KycViewModel } from '../viewmodels/kyc-viewmodel'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Inject } from 'vue-property-decorator'
import { AppProvider } from '@/app-providers'
import ConnectSolanaWallet from '@/modules/ido/components/connect-solana-wallet.vue'
import KycResult from '../components/KycResult.vue'
@Observer
@Component({
  components: {
    ConnectSolanaWallet,
    KycResult,
    'kyc-fee-dialog': () => import('../components/KycFeeDialog.vue'),
  },
})
export default class IdoPools extends Vue {
  @Inject() providers!: AppProvider
  @Provide() vm: KycViewModel = new KycViewModel()
  walletStore = walletStore

  destroyed() {
    this.vm.destroy()
  }
}
